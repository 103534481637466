import { Container, Nav, Navbar } from "react-bootstrap";

export function Header() {
  return <Navbar expand="lg" className="navbar navbar-expand-lg navbar-light shadow">
    <Container className="container d-flex justify-content-between align-items-center">
      <Navbar.Brand href="/home" className="navbar-brand text-success logo h1 align-self-center">
        <img src='/assets/img/justry.png' style={{ width: '200px'}} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="templatemo_main_nav" />
      <Navbar.Collapse id="templatemo_main_nav" className="align-self-center collapse navbar-collapse flex-fill  d-lg-flex justify-content-lg-between">
        <div className="flex-fill">
          <Nav className="nav navbar-nav d-flex justify-content-between mx-lg-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link>
            <Nav.Link href="/shop/categories/speciality-papers">Products</Nav.Link>
            <Nav.Link href="/contact">Contact</Nav.Link>
          </Nav>
        </div>
         {/* <div className="navbar align-self-center d-flex">
          <div className="d-lg-none flex-sm-fill mt-3 mb-4 col-7 col-sm-auto pr-3">
            <div className="input-group">
              <input type="text" className="form-control" id="inputMobileSearch" placeholder="Search ..." />
              <div className="input-group-text">
                <i className="fa fa-fw fa-search"></i>
              </div>
            </div>
          </div>
          <a className="nav-icon d-none d-lg-inline" href="#" data-bs-toggle="modal" data-bs-target="#templatemo_search">
            <i className="fa fa-fw fa-search text-dark mr-2"></i>
          </a>
          <a className="nav-icon position-relative text-decoration-none" href="#">
            <i className="fa fa-fw fa-cart-arrow-down text-dark mr-1"></i>
            <span className="position-absolute top-0 left-100 translate-middle badge rounded-pill bg-light text-dark">7</span>
          </a>
          <a className="nav-icon position-relative text-decoration-none" href="#">
            <i className="fa fa-fw fa-user text-dark mr-3"></i>
            <span className="position-absolute top-0 left-100 translate-middle badge rounded-pill bg-light text-dark">+99</span>
          </a>
        </div> */}
        </Navbar.Collapse>
    </Container>
  </Navbar>
}
